<template>
  <widget-base
    :loading="loading"
    :title="$t('pages.home.widgets.top_items.title')"
    :has-data="hasData"
    :can-refetch="canRefetch"
    no-data-icon="th-icon-shopping-cart"
    @refetch="refetch"
  >
    <el-table
      v-if="hasData"
      class="top-table-color"
      :data="items"
      :show-header="false"
      :border="false"
      height="100%"
    >
      <el-table-column
        type="index"
        class-name="top-table-color-light"
        :resizable="false"
        width="30px"
      />
      <el-table-column prop="name" show-overflow-tooltip resizable />
      <el-table-column
        prop="amount"
        min-width="65"
        show-overflow-tooltip
        align="right"
        resizable
      />
      <el-table-column
        prop="quantity"
        align="right"
        min-width="30px"
        show-overflow-tooltip
        :resizable="false"
        class-name="top-table-color-light"
      />
    </el-table>
  </widget-base>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import WidgetBase from '../widget-base'

export default {
  name: 'TopItems',
  components: {
    WidgetBase
  },
  props: {
    currentUserContext: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      items: [],
      loading: false,
      canRefetch: false
    }
  },
  computed: {
    selectedDates() {
      return this.currentUserContext.selectedDates
    },
    hasData() {
      return !!this.items.length
    },
    resourceQuery() {
      // global branch filter overrides local branch filter
      let branchNumber
      if (Number.isFinite(this.currentUserContext.currentBranchNumber)) {
        branchNumber = this.currentUserContext.currentBranchNumber
      }

      const query = {
        branch_number: branchNumber,
        start: this.selectedDates.start,
        end: this.selectedDates.end
      }

      return query
    }
  },
  watch: {
    selectedDates: function (value) {
      this.updateItems()
    }
  },
  mounted() {
    this.updateItems()
    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    async updateItems() {
      if (!this.selectedDates.start || !this.selectedDates.end) {
        this.items = []
        return
      }

      this.loading = true
      try {
        const { data } = await th
          .analytics({ timeout: 90000 })
          .getTopProductsReport({ query: this.resourceQuery })
        const { values } = data[0]

        this.items = values
          .filter(
            ({ currency }) =>
              currency === this.currentUserContext.selectedCurrency
          )
          .sort((a, b) => b.amountGrossTotal - a.amountGrossTotal)
          .slice(0, 5)
          .map((val, i) => ({
            index: i,
            name: val.productName,
            // TODO: get locale string from the configurations object
            // amount: val.amountGrossTotal.toLocaleString('de-DE', {
            //   style: 'currency',
            //   currency: val.currency
            // }),
            amount: this.$formatCurrency(val.amountGrossTotal, val.currency),
            quantity: val.quantity
          }))
      } catch (err) {
        this.canRefetch = true
        this.items = []
      } finally {
        this.loading = false
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.refetch()
      })
    },
    refetch() {
      this.updateItems()
      this.canRefetch = false
    }
  }
}
</script>

<style scoped>
.el-table :deep(.top-table-color) {
  color: #232e3d;
}

.el-table :deep(.top-table-color-light) {
  color: #a7abb1;
}

/* disable row highlighting on hover */
.el-table :deep(.el-table__body tr:hover > td) {
  background-color: unset;
}
</style>
